const weekdays = [
  {value: 'sunday', label: 'Domingo'},
  {value: 'monday', label: 'Lunes'},
  {value: 'tuesday', label: 'Martes'},
  {value: 'wednesday', label: 'Miercoles'},
  {value: 'thursday', label: 'Jueves'},
  {value: 'friday', label: 'Viernes'},
  {value: 'saturday', label: 'Sabado'},
]

export default weekdays